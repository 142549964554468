import React from "react";
import { BrowserRouter } from "react-router-dom";

// Define user context scope
import { UserProvider } from "./utils/user.context";

import PageRouter from "./utils/PageRouter";
import { Toaster } from "react-hot-toast";

function App() {
  return (
    <BrowserRouter>
      <UserProvider>
        <div className="App">
          <PageRouter />
          <Toaster
            toastOptions={{
              // Define default options for toaster
              duration: 4000,
              position: "bottom-center",
              iconTheme: {
                primary: "#6F3092",
                secondary: "#fff",
              },
            }}
          />
        </div>
      </UserProvider>
    </BrowserRouter>
  );
}

export default App;
