import { FC, useContext } from "react";

import { Row, Col } from "react-bootstrap";

import { db } from "../../utils/firebase-config.js";
import { doc, getDoc, updateDoc, setDoc } from "firebase/firestore";

import toast from "react-hot-toast";

import { UserContext } from "../../utils/user.context";
import { SongData } from "../../types/global.types";
import "./SongSearch.css";
import { postUpvote, VotingOptions } from "../../utils/playlist.utils";

const SearchResult: FC<SongData> = (songData): JSX.Element => {
  // Context API's
  const { user } = useContext(UserContext);

  // checks if song already exists and submits to firestore
  const submitSong = async () => {
    if (user !== null) {
      if (user.songSubmissionsRemaining > 0) {
        const songRef = doc(db, "music", songData.id);

        const docSnapshot = await getDoc(songRef);
        if (docSnapshot.exists()) {
          var docData = docSnapshot.data();
          // upvote the song if it already exists within the playlist
          if (docData.upvotes.indexOf(user.username) === -1) {
            if (docData.downvotes.indexOf(user.username) !== -1) {
              postUpvote(
                docSnapshot.id,
                user,
                docData.score,
                docData.upvotes,
                docData.downvotes,
                VotingOptions.Downvote
              );
            } else {
              postUpvote(
                docSnapshot.id,
                user,
                docData.score,
                docData.upvotes,
                docData.downvotes,
                VotingOptions.Novote
              );
            }
          }
        } else {
          // Create new doc in firestore if song does not already exist in playlist
          await setDoc(doc(db, "music", songData.id), {
            song: songData.title,
            artist: songData.artist,
            albumCover: songData.albumCover,
            track: songData.previewUrl,
            submittedBy: `${user.firstName} ${user.lastName}`,
            submissionDate: new Date(),
            score: 1,
            upvotes: [user.username],
            downvotes: [],
          }).then(async () => {
            const userRef = doc(db, "users", user.username);
            await updateDoc(userRef, {
              songSubmissionsRemaining: user.songSubmissionsRemaining - 1,
            });
          });
        }
      } else {
        toast.error(
          "We know you love to boogie, but you have submitted the maximum amount of songs for now. Please try again later."
        );
      }
    }
  };

  return (
    <li className="search-result row" onClick={() => submitSong()}>
      <Row className="align-items-center">
        <Col xs={2} md={1} className="col s4 search-img-container">
          <img
            className="search-album-cover"
            src={songData.albumCover}
            alt="album-cover"
          />
        </Col>
        <Col>
          <div className="col s8">
            <b>{songData.title}</b>
          </div>
          <div className="col s8">
            <i>{songData.artist}</i>
          </div>
        </Col>
      </Row>
    </li>
  );
};

export default SearchResult;
