import { FC } from "react";

// Bootstrap components
import { Container, Row, Nav } from "react-bootstrap";

import "./footer.css";

import FooterIcon from "./img/footer.png";

const Footer: FC = () => {
  return (
    <footer id="footer">
      <Container className="footer-wrapper">
        <Row className="justify-content-center">
          <Row className="justify-content-center">
            <img className="footer-icon" src={FooterIcon} alt="footer-icon" />
          </Row>
          <Row>
            <h1>Matt & Becca</h1>
          </Row>
          <br />
          <Row>
            <Nav
              className="justify-content-center footer-body-font"
              activeKey="/"
            >
              <Nav.Item>
                <Nav.Link className="footer-navlink" href="/gallery">
                  Gallery
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="footer-navlink" href="/story">
                  Our Story
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="footer-navlink" href="/travel">
                  Travel
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="footer-navlink"
                  href="https://giftlist.kuoni.co.uk/gift-contribute?ref=43489"
                >
                  Giftlist
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Row>
          <br />
          <br />
          <br />
          <Row className="justify-content-center footer-body-font">
            &copy; Matt Cresswell. All Rights Reserved.
          </Row>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
