import { FC } from "react";

import { Container, Row, Col } from "react-bootstrap";

import "./Intro.css";
import "./Fireflies.css";
import Arrow from "./assets/arrow.gif";

const Intro: FC = () => {
  return (
    <section className="video-container white_txt">
      <div className="overlay"></div>
      <video
        src={
          "https://storage.googleapis.com/matt-becca-test-image-assets/background.mp4"
        }
        className="background-video"
        autoPlay
        loop
        muted
      />

      <div className="firefly-container">
        <div className="firefly"></div>
        <div className="firefly"></div>
        <div className="firefly"></div>
        <div className="firefly"></div>
        <div className="firefly"></div>
        <div className="firefly"></div>
        <div className="firefly"></div>
        <div className="firefly"></div>
        <div className="firefly"></div>
        <div className="firefly"></div>
        <div className="firefly"></div>
        <div className="firefly"></div>
        <div className="firefly"></div>
        <div className="firefly"></div>
      </div>

      <div className="home_txt">
        {/* Intro Text */}
        <Container>
          <Row>
            <Col md={{ span: 6, offset: 3 }}>
              <div className="title">
                Rebecca Adams & <br /> Matthew Cresswell
              </div>
              <div className="bullet">
                <span>
                  15 <b>09</b>
                </span>
              </div>
              <div className="year">2023</div>
            </Col>
          </Row>
        </Container>
        <a href="#invited" className="intro_down">
          <span>
            <img src={Arrow} alt="intro_down" className="down-arrow" />
          </span>
        </a>
      </div>
    </section>
  );
};

export default Intro;
