import { FC } from "react";

import { Carousel } from "react-bootstrap";

import "./header.css";

interface HeaderProps {
  headerText: string;
}

const urls: Array<string> = [
  "https://storage.googleapis.com/matt-becca-test-image-assets/header-photos/carousel-holding-hands.png",
  "https://storage.googleapis.com/matt-becca-test-image-assets/header-photos/carousel-tree.png",
  "https://storage.googleapis.com/matt-becca-test-image-assets/header-photos/carousel-laughing.png",
  "https://storage.googleapis.com/matt-becca-test-image-assets/header-photos/carousel-carry.png",
];

const Header: FC<HeaderProps> = ({ headerText }) => {
  return (
    <section id="header-wrapper">
      <Carousel
        fade
        controls={false}
        indicators={false}
        interval={5000}
        keyboard={false} // remove keyboard commands
      >
        {urls.map((url, index) => {
          return (
            <Carousel.Item key={`header-${index}`}>
              <h2 className="header-heading">{headerText}</h2>
              <img
                className="d-block w-100 h-100"
                src={url}
                alt={`${index}-carousel`}
              />
            </Carousel.Item>
          );
        })}
      </Carousel>
    </section>
  );
};

export default Header;
