import { FC, useContext, useEffect } from "react";

import { UserContext } from "../../utils/user.context";
import { updateLastPageVisit } from "../../utils/page.utils";

import Intro from "./Intro";
import Invited from "./Invited";
import Schedule from "./Schedule";
import LandingPageMap from "./LandingPageMap";
import Footer from "../../components/Footer";

import NavBar from "../../components/NavBar";

const LandingPage: FC = () => {
  // Context API's
  const { user } = useContext(UserContext);

  useEffect(() => {
    if (user) {
      updateLastPageVisit("Homepage", user.username);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main>
      <NavBar /> {/* NavBar */}
      {/* Page Sections */}
      <Intro />
      <Invited />
      <Schedule />
      <LandingPageMap />
      <Footer />
    </main>
  );
};

export default LandingPage;
