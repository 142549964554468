import { FC, useState, useEffect } from "react";
import { InputGroup, FormControl } from "react-bootstrap";

import SearchResult from "./SearchResult";
import "./SongSearch.css";
import { SongData } from "../../types/global.types";

const SongSearch: FC = () => {
  const [searchInput, setSearchInput] = useState<string>("");
  const [accessToken, setAccessToken] = useState<string>("");
  const [searchResponse, setSearchResponse] = useState<Array<SongData>>([]);

  useEffect(() => {
    // API Access Token
    var authParams = {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body:
        "grant_type=client_credentials&client_id=" +
        process.env.REACT_APP_SPOTIFY_CLIENT_ID +
        "&client_secret=" +
        process.env.REACT_APP_SPOTIFY_CLIENT_SECRET,
    };

    fetch("https://accounts.spotify.com/api/token", authParams)
      .then((result) => result.json())
      .then((data) => setAccessToken(data.access_token));
  }, []);

  // Search
  async function searchSongs() {
    // Get request using search to get the artist ID
    var artistParams = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    };
    await fetch(
      "https://api.spotify.com/v1/search?q=" +
        searchInput +
        "&type=track" +
        "&limit=5",
      artistParams
    )
      .then((res) => res.json())
      .then((data) => {
        let songResponse: Array<SongData> = [];
        data.tracks.items.map((song: any) =>
          songResponse.push({
            id: song.id,
            title: song.name,
            artist: song.artists[0].name,
            album: song.album.name,
            albumCover: song.album.images[0].url,
            previewUrl: song.preview_url,
          })
        );
        setSearchResponse(songResponse);
      });
  }

  //Set query state on each text input
  const handleInputChange = (value: string) => {
    setSearchInput(value);

    if (value.length > 4) {
      searchSongs();
    } else {
      setSearchResponse([]);
    }
  };

  return (
    <>
      <div className="song-input-wrapper">
        <InputGroup className="mb-3">
          <FormControl
            placeholder="Search for a Song"
            className="search-bar"
            type="input"
            value={searchInput}
            onChange={(event) => handleInputChange(event.target.value)}
          />
        </InputGroup>
      </div>

      <div className="search-results">
        {searchResponse.length > 0 ? (
          <ul className="search-results-list">
            {searchResponse.map((song, index) => (
              <span onClick={() => handleInputChange("")} key={index}>
                <SearchResult {...song} />
              </span>
            ))}
          </ul>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default SongSearch;
