import { FC, useContext, useEffect } from "react";

import { UserContext } from "../../utils/user.context";
import { updateLastPageVisit } from "../../utils/page.utils";

// Bootstrap components
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";

import Header from "../../components/Header";
import { AccomodationLinks } from "./AccomodationLinks";
import Divider from "../../components/Divider";
import { TravelMap } from "./TravelMap";
import PrimaryHeading from "../../components/PrimaryHeading";
import Footer from "../../components/Footer";

import NavBar from "../../components/NavBar";

import "./travel.css";

const Travel: FC = () => {
  // Context API's
  const { user } = useContext(UserContext);

  useEffect(() => {
    if (user) {
      updateLastPageVisit("Travel & Accomodation", user.username);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <NavBar /> {/* NavBar */}
      <Header headerText="Travel & Accomodation" />
      <br />
      <br />
      <section id="travel">
        <PrimaryHeading preSlash="How to" postSlash="Find Us" />
        <Container className="travel-card">
          <Row style={{ display: "flex" }}>
            <Col xs={12} md={6} className="travel-card-wrapper">
              <Card>
                <Card.Img
                  className="travel-card-img"
                  variant="top"
                  src="https://www.woottonparkweddings.co.uk/wp-content/uploads/sites/54/2019/06/Wootton-By-Road-1024x723.jpg"
                />
                <Card.Body>
                  <Card.Title>
                    <h2>By Road</h2>
                  </Card.Title>
                  <Card.Text className="travel-text">
                    Leave the M40 at Junction 16 and follow the A3400 through
                    <b className="travel-highlighted-text"> Henley-in-Arden</b>.
                    <br />
                    <br />
                    In Wootton Wawen turn at the Bull’s Head onto the{" "}
                    <b className="travel-highlighted-text">
                      B4089 Alcester Rd to Great Alne/Aston Cantlow
                    </b>
                    . After 300m turn left (Still on the B4089 to Aston
                    Cantlow).
                    <br />
                    <br />
                    After approximately 3/4 mile turn right into{" "}
                    <b className="travel-highlighted-text">
                      Wootton Park Farm & Business Centre
                    </b>
                    . Follow the drive for half a mile and the car park is on
                    the right at the top of the drive.
                    <br />
                    <br />
                    <br />
                  </Card.Text>
                  <Button
                    className="travel-button"
                    onClick={() =>
                      window.open(
                        "https://www.google.com/maps/dir/?api=1&destination=Wootton+Park,+Wootton+Wawen,+Henley-in-Arden+B95+6HJ&travelmode=driving",
                        "_blank"
                      )
                    }
                  >
                    Directions
                  </Button>
                </Card.Body>
              </Card>
            </Col>

            <Row className="travel-divider justify-content-center">
              <Divider />
            </Row>

            <Col xs={12} md={6} className="travel-card-wrapper">
              <Card className="travel-card">
                <Card.Img
                  className="travel-card-img"
                  variant="top"
                  src="https://www.woottonparkweddings.co.uk/wp-content/uploads/sites/54/2019/06/Wootton-By-Train-1024x683.jpg"
                />
                <Card.Body>
                  <Card.Title>
                    <h2>By Rail</h2>
                  </Card.Title>
                  <Card.Text className="travel-text">
                    The nearest station is{" "}
                    <b className="travel-highlighted-text">
                      Wootton Wawen (code WWW)
                    </b>{" "}
                    on the Stratford-Upon-Avon to Birmingham line.
                    <br />
                    <br />
                    It’s a{" "}
                    <b className="travel-highlighted-text">request stop </b>with
                    trains approximately every hour during the working day.
                    <br />
                    <br />
                    From the station either arrange for somebody to collect you
                    which is only{" "}
                    <b className="travel-highlighted-text">2 mins</b> by car or
                    you can walk to Wootton Park.
                    <br />
                    <br />
                    <br />
                  </Card.Text>
                  <Button
                    className="travel-button"
                    onClick={() =>
                      window.open(
                        "https://www.google.com/maps/dir/?api=1&destination=Wootton+Park,+Wootton+Wawen,+Henley-in-Arden+B95+6HJ&travelmode=transit",
                        "_blank"
                      )
                    }
                  >
                    Find My Train
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
      <br />
      <br />
      <Row className="justify-content-center">
        <Divider />
      </Row>
      <section id="accomodation">
        <PrimaryHeading preSlash="Where" postSlash="to Stay" />
        <AccomodationLinks />
        <p className="travel-summary">
          Wootton Park also has{" "}
          <a
            className="travel-highlighted-text"
            href="https://www.woottonparkpods.co.uk/"
            target="_blank"
            rel="noreferrer"
          >
            glamping pods
          </a>{" "}
          and{" "}
          <a
            className="travel-highlighted-text"
            href="https://www.woottonparkweddings.co.uk/book-camping/"
            target="_blank"
            rel="noreferrer"
          >
            camping facilities
          </a>{" "}
          onsite that you can hire!
          <br />
          Further accomodation is available in the nearby towns of
          Stratford-upon-Avon or Henley-in-Arden.
          <br />
          We've provided a few options below.
          <br />
          Or...yano...just ask Connie...
        </p>
        <br />
        <TravelMap />
      </section>
      <Footer />
    </>
  );
};

export default Travel;
