import { FC, useContext, useState, useEffect } from "react";

import { Row, Col } from "react-bootstrap";
import "./Invited.css";

// modules
import AOS from "aos";
import "aos/dist/aos.css";

// components
import { RSVP } from "./RSVP";
import { WeddingBreakfast } from "./WeddingBreakfast";
import PrimaryHeading from "../../../components/PrimaryHeading";
import PrimaryButton from "../../../components/PrimaryButton";
import Divider from "../../../components/Divider";

// scripts
import { UserContext } from "../../../utils/user.context";
import {
  Countdown,
  countdownToWedding,
  updateTimerState,
} from "./countdownCalculator";

const Invited: FC = () => {
  const { user } = useContext(UserContext);

  // provide initial dates for countdown constructor
  const weddingDate = new Date("Fri Sep 15 2023 14:00:00");
  const now = new Date();

  // state for wedding
  const [countdown, setCountdown] = useState<Countdown>(
    countdownToWedding(weddingDate, now)
  );

  useEffect(() => {
    const interval = setInterval(() => {
      if (!countdown.weddingStarted) {
        setCountdown(updateTimerState(countdown));
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [countdown]);

  // Modal states
  const [RsvpModal, setRsvpModal] = useState(false);
  const [WeddingBreakfastModal, setWeddingBreakfastModal] = useState(false);

  const displayRsvpModal = () => setRsvpModal(true);
  const closeRsvpModal = () => setRsvpModal(false);
  const displayWeddingBreakfastModal = () => setWeddingBreakfastModal(true);
  const closeWeddingBreakfastModal = () => setWeddingBreakfastModal(false);

  // initiate scroll animations
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <section id="invited" className="married clearfix">
      <RSVP show={RsvpModal} onHide={closeRsvpModal} backdrop="static" />
      <WeddingBreakfast
        show={WeddingBreakfastModal}
        onHide={closeWeddingBreakfastModal}
        backdrop="static"
      />
      <div className="container">
        <Row>
          <Col sm={12} md>
            <div
              className="im1 parallax skrollable skrollable-between"
              data-aos="fade-left"
              data-bottom="left:20%"
              data-center="left:0"
              data-image="images/blue/im1.jpg"
              style={{
                left: "12.7517%",
              }}
            >
              <div className="im_arrows" />
            </div>
          </Col>

          <Col xs={{ order: 3 }} sm={{ order: 3 }} md>
            <div
              className="im2 parallax skrollable skrollable-between"
              data-aos="fade-right"
              data-bottom="right:20%"
              data-center="right:0"
              data-image="images/blue/im2.jpg"
              style={{
                right: "12.7517%",
              }}
            >
              <div className="im_arrows" />
            </div>
          </Col>

          <Col
            xs={{ span: 12, order: 2 }}
            sm={{ span: 12, order: 2 }}
            md
            data-aos="fade"
          >
            <PrimaryHeading preSlash="You're" postSlash="Invited" />
            <div>
              On the 15th of September, 2023
              <br />
              {user ? (
                <b>
                  {user.firstName} {user.lastName}{" "}
                  {user.partner ? <>&amp; {user.partner.name}</> : <></>}
                </b>
              ) : (
                <b>YOU</b>
              )}
              <br />
              are invited to Wootton Wawen, Warwickshire <br />
              {user?.dayGuest ? (
                <span>to join us on our wedding day.</span>
              ) : (
                <span>
                  to celebrate with us on the <br /> evening of our wedding.
                </span>
              )}
              <br />
              <br />
              {user?.rsvpResponse?.attending ? (
                <>
                  <PrimaryButton
                    handleClick={() =>
                      (window.location.href = `${process.env.REACT_APP_HOST_NAME}gallery`)
                    }
                    text="See our Gallery"
                  />
                  <PrimaryButton
                    handleClick={() =>
                      (window.location.href = `https://giftlist.kuoni.co.uk/gift-contribute?ref=43489`)
                    }
                    text="See our Giftlist"
                  />
                </>
              ) : (
                <PrimaryButton handleClick={displayRsvpModal} text="RSVP Now" />
              )}
              <br />
            </div>
            <div className="married_coundown is-countdown">
              <span className="countdown-row countdown-show3">
                <span className="countdown-section">
                  <span className="countdown-amount">{countdown.days}</span>
                  <span className="countdown-period">
                    {countdown.dayWording}
                  </span>
                </span>
                <span className="countdown-section">
                  <span className="countdown-amount">{countdown.hours}</span>
                  <span className="countdown-period">
                    {countdown.hourWording}
                  </span>
                </span>
                <span className="countdown-section">
                  <span className="countdown-amount">{countdown.minutes}</span>
                  <span className="countdown-period">
                    {countdown.minuteWording}
                  </span>
                </span>
                <span className="countdown-section">
                  <span className="countdown-amount">{countdown.seconds}</span>
                  <span className="countdown-period">
                    {countdown.secondWording}
                  </span>
                </span>
              </span>
            </div>
          </Col>
        </Row>

        <div className="double_arrow" />
      </div>

      <Divider />
    </section>
  );
};

export default Invited;
