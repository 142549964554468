import React from "react";

import { Container, Row } from "react-bootstrap";

import "./loading.css";

import LoadingGif from "./img/loading.gif";

const Loading: React.FC = () => {
  return (
    <Container>
      <Row className="justify-content-center">
        <img src={LoadingGif} alt="loading" id="loading" />
      </Row>
    </Container>
  );
};

export default Loading;
