import { db } from "./firebase-config.js";
import { doc, updateDoc } from "firebase/firestore";

import { User } from "../types/global.types";

/* Playlist Types */

export interface PlaylistItem {
  spotifyId: string;
  artist: string;
  albumCover: string;
  song: string;
  submittedBy: string;
  track: string;
  score: number;
  upvotes: Array<string>;
  downvotes: Array<string>;
  voteFormatting: CurrentUserVote;
  submissionDate?: Date;
}

export enum VotingOptions {
  Upvote = "UPVOTE",
  Downvote = "DOWNVOTE",
  Novote = "NOVOTE",
}

export interface CurrentUserVote {
  vote: VotingOptions;
  upvoteColor: string;
  downvoteColor: string;
}

// voting state types
export const upVoted = {
  vote: VotingOptions.Upvote,
  upvoteColor: "#50C878",
  downvoteColor: "#ddd",
};

export const downVoted = {
  vote: VotingOptions.Downvote,
  upvoteColor: "#ddd",
  downvoteColor: "#FF5733",
};

export const noVoted = {
  vote: VotingOptions.Novote,
  upvoteColor: "#ddd",
  downvoteColor: "#ddd",
};

// sets the initial state of the users current vote
export const setVote = (
  username: string,
  upvotes: Array<string>,
  downvotes: Array<string>
): CurrentUserVote => {
  if (upvotes.includes(username)) {
    return upVoted;
  } else if (downvotes.includes(username)) {
    return downVoted;
  }
  return noVoted;
};

// handle upvotes and write to firestore
export const postUpvote = async (
  id: string,
  user: User | null,
  score: number,
  upvotes: Array<string>,
  downvotes: Array<string>,
  voteFormat: VotingOptions
) => {
  if (user != null) {
    // remove upvote if user has already upvoted
    if (voteFormat === VotingOptions.Upvote) {
      let index = upvotes.indexOf(user.username);
      upvotes.splice(index, 1);
      score--;
    } else if (voteFormat === VotingOptions.Downvote) {
      // remove downvote for user if already downvoted and push username to upvotes array
      upvotes.push(user.username);
      let index = downvotes.indexOf(user.username);
      downvotes.splice(index, 1);
      score = score + 2;
    } else {
      // push user to upvote if currently no vote
      upvotes.push(user.username);
      score++;
    }

    const songRef = doc(db, "music", id);

    // update the doc with the latest vote
    await updateDoc(songRef, {
      score,
      upvotes,
      downvotes,
    });
  }
};

// handle downvotes and write to firestore
export const postDownvote = async (
  id: string,
  user: User | null,
  score: number,
  upvotes: Array<string>,
  downvotes: Array<string>,
  voteFormat: VotingOptions
) => {
  if (user != null) {
    // remove downvote if user has already downvoted
    if (voteFormat === VotingOptions.Downvote) {
      let index = downvotes.indexOf(user.username);
      downvotes.splice(index, 1);
      score++;
    } else if (voteFormat === VotingOptions.Upvote) {
      // remove upvote for user if already upvoted and push username to downvotes array
      downvotes.push(user.username);
      let index = upvotes.indexOf(user.username);
      upvotes.splice(index, 1);
      score = score - 2;
    } else {
      // push user to downvotes if currently no vote
      downvotes.push(user.username);
      score--;
    }

    const songRef = doc(db, "music", id);

    // update the doc with the latest vote
    await updateDoc(songRef, {
      score,
      upvotes,
      downvotes,
    });
  }
};
