import { FC } from "react";

import "./PrimaryButton.css";
import "../../fonts.css";

interface PrimaryButtonProps {
  handleClick: () => void;
  text: string;
}

const PrimaryButton: FC<PrimaryButtonProps> = ({ handleClick, text }) => {
  return (
    <div className="button-wrapper">
      <button onClick={handleClick} className="cta">
        <span>{text}</span>
        <svg width="13px" height="10px" viewBox="0 0 13 10">
          <path d="M1,5 L11,5"></path>
          <polyline points="8 1 12 5 8 9"></polyline>
        </svg>
      </button>
    </div>
  );
};

export default PrimaryButton;
