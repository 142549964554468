import { FC, useContext, useEffect } from "react";

import { UserContext } from "../../utils/user.context";
import { updateLastPageVisit } from "../../utils/page.utils";

import Row from "react-bootstrap/Row";

import Header from "../../components/Header";
import PrimaryHeading from "../../components/PrimaryHeading";
import Divider from "../../components/Divider";
import Footer from "../../components/Footer";

import NavBar from "../../components/NavBar";

import "./questions.css";

const Questions: FC = () => {
  // Context API's
  const { user } = useContext(UserContext);

  useEffect(() => {
    if (user) {
      updateLastPageVisit("Q&A", user.username);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <NavBar /> {/* NavBar */}
      <Header headerText="Questions?" />
      <br />
      <br />
      <section id="questions">
        <PrimaryHeading preSlash="Where's the" postSlash="Venue?" />
        <div className="questions-text">
          Wootton Park, Wootton Wawen, Henley-in-Arden B95 6HJ
        </div>
        <Row className="justify-content-center">
          <Divider />
        </Row>
        <PrimaryHeading preSlash="Getting to the" postSlash="Venue?" />
        <div className="questions-text">
          Many of you will be (quite rightly) partaking in a drink or two, or
          maybe you just don't feel like driving on the day! Don't worry, we've
          got you covered, you can book a taxi via one of the local companies
          from your accomodation.
          <br /> <br />
          <a href="https://www.taxiunitedwarwick.co.uk/">Taxi United</a>,{" "}
          <a href="http://www.taxisevenwarwick.com/">Taxi Seven Warwick</a> or{" "}
          <a href="https://taxitogowarwick.business.site/?utm_source=gmb&utm_medium=referral">
            Taxi To Go
          </a>
          <br /> <br />
          If you are planning to drive to the venue yourself, there is parking
          availability for up to 50 vehicles on the day, however it may become
          quite busy.
        </div>
        <Row className="justify-content-center">
          <Divider />
        </Row>
        <PrimaryHeading preSlash="Do you have a" postSlash="Gift List?" />
        <div className="questions-text">
          We've been asked what we'd like for a gift and the answer is simple:
          you, at our wedding, having a great time and throwing crazy shapes on
          the dancefloor. We are lucky enough to already have an amazing home
          filled with amazing things from our 6 years of living together. <br />
          But if you insist, we have booked a fantastic honeymoon to the
          Carribean islands of Barbados and St. Lucia in December this year,
          where we plan to go diving, adventuring and drink plenty of cocktails.
          So if you would like to contribute to our special few weeks, you can
          add to our wishing well via the link below. Then when we're by the
          beach with Piña Colada in hand, we'll sit back and know that it is
          truely thanks to all of you! <br />
          <br />
          <a href="https://giftlist.kuoni.co.uk/gift-contribute?ref=43489">
            https://giftlist.kuoni.co.uk/gift-contribute?ref=43489
          </a>
        </div>
        <Row className="justify-content-center">
          <Divider />
        </Row>
        <PrimaryHeading preSlash="Recommended" postSlash="Attire?" />
        <div className="questions-text">
          We would never impose....surprise us! <br /> <br />
          However, traditional wedding attire is recommended.
        </div>
        <Row className="justify-content-center">
          <Divider />
        </Row>
        <PrimaryHeading preSlash="Can we take" postSlash="Pictures?" />
        <div className="questions-text">
          We insist, and make sure to get some embarrassing ones of the bride
          and groom towards the end of the night. However, please refrain from
          taking photos during the ceremony.{" "}
        </div>
        <Row className="justify-content-center">
          <Divider />
        </Row>
        <PrimaryHeading preSlash="Will there be" postSlash="Dancing?" />
        <div className="questions-text">
          <img
            src="https://media.tumblr.com/1c136ea0dfedf3bb272bb4a47ee6f96c/tumblr_inline_mggwv0D6aZ1rvodg7.gif"
            alt="Yes"
          />
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Questions;
