import { FC, useEffect, useContext } from "react";

// modules
import AOS from "aos";
import "aos/dist/aos.css";

import "./Schedule.css";

import { UserContext } from "../../../utils/user.context";

const Schedule: FC = () => {
  const { user } = useContext(UserContext);

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <section id="schedule">
      <div className="container">
        {user?.dayGuest ? (
          <>
            <div className="schedule-item">
              <div className="schedule-img"></div>

              <div
                data-aos="fade-left"
                className="schedule-content schedule-card js--fadeInLeft"
              >
                <div className="schedule-img-header guest-arrival">
                  <h2>Guest Arrival</h2>
                </div>
                <div className="date">13:00</div>
                <blockquote className="schedule-text">
                  Arrive early afternoon to grab a pint with the groom and
                  catchup before the ceremony.
                </blockquote>
              </div>
            </div>

            <div className="schedule-item">
              <div className="schedule-img"></div>

              <div
                data-aos="fade-right"
                className="schedule-content schedule-card js--fadeInRight"
              >
                <div className="schedule-img-header ceremony-starts">
                  <h2>Ceremony Starts</h2>
                </div>
                <div className="date">14:00</div>
                <blockquote className="schedule-text">
                  Take your seat and grab a tissue or three.
                </blockquote>
              </div>
            </div>

            <div className="schedule-item">
              <div className="schedule-img"></div>

              <div
                data-aos="fade-left"
                className="schedule-content schedule-card js--fadeInLeft"
              >
                <div className="schedule-img-header wedding-breakfast">
                  <h2>Wedding Breakfast</h2>
                </div>
                <div className="date">16:00</div>
                <blockquote className="schedule-text">
                  Once the formalities are complete, you'll be pulling up a
                  chair to grab a bite to eat.
                </blockquote>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
        <div className="schedule-item">
          <div className="schedule-img"></div>

          <div
            data-aos="fade-right"
            className="schedule-content schedule-card js--fadeInRight"
          >
            <div className="schedule-img-header wedding-evening">
              <h2>Evening Guests</h2>
            </div>
            <div className="date">19:00</div>
            <blockquote className="schedule-text">
              Join us to celebrate the evening of our wedding.
            </blockquote>
          </div>
        </div>

        <div className="schedule-item">
          <div className="schedule-img"></div>

          <div
            data-aos="fade-left"
            className="schedule-content schedule-card js--fadeInLeft"
          >
            <div className="schedule-img-header wedding-disco">
              <h2>Dancing</h2>
            </div>
            <div className="date">19:30</div>
            <blockquote className="schedule-text">
              We expect you to be throwing some crazy shapes on the dancefloor.
            </blockquote>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Schedule;
