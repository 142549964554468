import { useState, useContext, useEffect } from "react";

import { db } from "../../../utils/firebase-config.js";
import { doc, writeBatch } from "firebase/firestore";

import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import ListGroup from "react-bootstrap/ListGroup";

import PrimaryButton from "../../../components/PrimaryButton";
import toast from "react-hot-toast";

import {
  WeddingBreakfastResponse,
  MenuItem,
} from "../../../types/global.types";
import { UserContext } from "../../../utils/user.context";

export function WeddingBreakfast(props: any) {
  const { user, buildUserContext } = useContext(UserContext);

  const [modalView, setModalView] = useState<string>("personal-selection");
  const [starter, setStarter] = useState<MenuItem>({
    index: 0,
    food: "Fusion of Smoked and Hot Smoked Salmon, Samphire & Horse Radish Cream",
  });
  const [main, setMain] = useState<MenuItem>({
    index: 0,
    food: "Chicken Breast Wrapped in Bacon & Stuffed with Spinach & Cream Cheese",
  });
  const [dessert, setDessert] = useState<MenuItem>({
    index: 0,
    food: "Salted Caramel Chocolate Tart",
  });
  const [dietaryRequirements, setDietaryRequirements] = useState<string>("");

  const setVeganOptions = () => {
    setStarter({
      index: 2,
      food: "Vegan Option",
    });
    setMain({
      index: 2,
      food: "Vegan Option",
    });
    setDessert({
      index: 2,
      food: "Vegan Option",
    });
  };

  // populates input fields with existing RSVP responses
  useEffect(() => {
    if (user != null) {
      if (modalView === "personal-selection") {
        if (user.weddingBreakfastResponse) {
          setStarter(user.weddingBreakfastResponse.starter);
          setMain(user.weddingBreakfastResponse.main);
          setDessert(user.weddingBreakfastResponse.dessert);
          setDietaryRequirements(
            user.weddingBreakfastResponse.dietaryRequirements
          );
        }
      } else {
        setStarter({
          index: 0,
          food: "Fusion of Smoked and Hot Smoked Salmon, Samphire & Horse Radish Cream",
        });
        setMain({
          index: 0,
          food: "Chicken Breast Wrapped in Bacon & Stuffed with Spinach & Cream Cheese",
        });
        setDessert({
          index: 0,
          food: "Salted Caramel Chocolate Tart",
        });
        setDietaryRequirements("");
      }
    }
  }, [user, modalView]);

  // uploads user response to firestore.
  const submitResponses = async () => {
    if (user != null) {
      // construct data object
      let weddingBreakfastResponse: WeddingBreakfastResponse = {
        starter,
        main,
        dessert,
        dietaryRequirements,
        submissionDate: new Date(),
        responder: user.username,
      };

      // Get a new write batch
      const batch = writeBatch(db);

      let userRef = doc(db, "users", user.username);

      // if partner view, switch user reference
      if (modalView === "partner-selection" && user.partner) {
        userRef = doc(db, "users", user.partner.username);
      }

      batch.update(userRef, { weddingBreakfastResponse });

      batch
        .commit()
        .then(() => {
          setModalView("thank-you");
        })
        .catch(() => {
          toast.error(
            `Unfortunately, your RSVP response did not submit. Please try again.`
          );
        });

      buildUserContext(user.phoneNumber);
    }
  };

  return (
    <>
      <Modal {...props}>
        <Modal.Body className="modal-container">
          <Modal.Header closeButton className="modal-title" />
          {modalView === "personal-selection" ||
          modalView === "partner-selection" ? (
            <>
              <Form>
                <Modal.Title>Starter</Modal.Title>
                <ListGroup>
                  <ListGroup.Item
                    onClick={() =>
                      setStarter({
                        index: 0,
                        food: "Fusion of Smoked and Hot Smoked Salmon, Samphire & Horse Radish Cream",
                      })
                    }
                    className={
                      starter.index === 0
                        ? "modal-response response-selected"
                        : "modal-response"
                    }
                  >
                    Hot Smoked Salmon, Samphire & Horse Radish Cream
                  </ListGroup.Item>
                  <ListGroup.Item
                    onClick={() =>
                      setStarter({
                        index: 1,
                        food: "Halloumi Stuffed Garlic Mushrooms",
                      })
                    }
                    className={
                      starter.index === 1
                        ? "modal-response response-selected"
                        : "modal-response"
                    }
                  >
                    Halloumi Stuffed Garlic Mushrooms
                  </ListGroup.Item>
                  <ListGroup.Item
                    onClick={setVeganOptions}
                    className={
                      starter.index === 2
                        ? "modal-response response-selected"
                        : "modal-response"
                    }
                  >
                    I am Vegan
                  </ListGroup.Item>
                </ListGroup>
                <br />
                <Modal.Title>Mains</Modal.Title>
                <ListGroup>
                  <ListGroup.Item
                    onClick={() =>
                      setMain({
                        index: 0,
                        food: "Chicken Breast Wrapped in Bacon & Stuffed with Spinach & Cream Cheese",
                      })
                    }
                    className={
                      main.index === 0
                        ? "modal-response response-selected"
                        : "modal-response"
                    }
                  >
                    Chicken Breast Wrapped in Bacon & Stuffed with Spinach &
                    Cream Cheese
                  </ListGroup.Item>
                  <ListGroup.Item
                    onClick={() =>
                      setMain({
                        index: 1,
                        food: "Seasonal Risotto",
                      })
                    }
                    className={
                      main.index === 1
                        ? "modal-response response-selected"
                        : "modal-response"
                    }
                  >
                    Vegetarian Seasonal Risotto
                  </ListGroup.Item>
                  <ListGroup.Item
                    onClick={setVeganOptions}
                    className={
                      main.index === 2
                        ? "modal-response response-selected"
                        : "modal-response"
                    }
                  >
                    I am Vegan
                  </ListGroup.Item>
                </ListGroup>
                <br />
                <Modal.Title>Dessert</Modal.Title>
                <ListGroup>
                  <ListGroup.Item
                    onClick={() =>
                      setDessert({
                        index: 0,
                        food: "Salted Caramel Chocolate Tart",
                      })
                    }
                    className={
                      dessert.index === 0
                        ? "modal-response response-selected"
                        : "modal-response"
                    }
                  >
                    Salted Caramel Chocolate Tart
                  </ListGroup.Item>
                  <ListGroup.Item
                    onClick={() =>
                      setDessert({
                        index: 1,
                        food: "Cheesecake",
                      })
                    }
                    className={
                      dessert.index === 1
                        ? "modal-response response-selected"
                        : "modal-response"
                    }
                  >
                    Cheesecake
                  </ListGroup.Item>
                  <ListGroup.Item
                    onClick={setVeganOptions}
                    className={
                      dessert.index === 2
                        ? "modal-response response-selected"
                        : "modal-response"
                    }
                  >
                    I am Vegan
                  </ListGroup.Item>
                </ListGroup>
                <br />
                <Form.Group controlId="exampleForm.ControlTextarea1">
                  <Form.Label>
                    Any further dietary requirements we should pass on to the
                    chefs?
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    id="dietary-requirements"
                    value={dietaryRequirements}
                    onChange={(e) => setDietaryRequirements(e.target.value)}
                    rows={3}
                  />
                </Form.Group>
              </Form>
              <Modal.Footer style={{ border: "none" }}>
                <PrimaryButton
                  handleClick={() => submitResponses()}
                  text="Submit"
                />
              </Modal.Footer>
            </>
          ) : (
            <>
              <div className="modal-title-center">Thank You</div>
              <br />
              <div className="modal-text">
                Now the most important part of the day is sorted, <br /> what do
                you want to checkout next?
              </div>
              {user?.partner ? (
                <PrimaryButton
                  handleClick={() => setModalView("partner-selection")}
                  text={`Select ${user?.partner.name.substring(
                    0,
                    user?.partner.name.indexOf(" ")
                  )}'s Food`}
                />
              ) : (
                <></>
              )}
              <PrimaryButton
                handleClick={() =>
                  window.open(
                    "https://giftlist.kuoni.co.uk/gift-contribute?ref=43489",
                    "_blank"
                  )
                }
                text="See Giftlist"
              />
              <PrimaryButton
                handleClick={() =>
                  (window.location.href = `${process.env.REACT_APP_HOST_NAME}playlist`)
                }
                text="Select More Music"
              />
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
