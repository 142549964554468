import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export const AccomodationLinks = () => {
  return (
    <Container style={{ width: "60%", marginBottom: "60px" }}>
      <Row className="justify-content-center">
        <Col xs={12} sm={6} md={3}>
          <img
            className="accomodation-icon"
            src={
              "https://cdn5.f-cdn.com/contestentries/995221/14777422/58eb66f38d645_thumb900.jpg"
            }
            alt="airbnb"
            onClick={() =>
              window.open(
                "https://www.airbnb.co.uk/s/Wootton-Wawen--UK/homes?tab_id=home_tab&refinement_paths%5B%5D=%2Fhomes&flexible_trip_lengths%5B%5D=one_week&price_filter_input_type=0&price_filter_num_nights=5&query=Wootton%20Wawen%2C%20UK&place_id=ChIJQyCCasTFcEgRvNLeexjjyJg&date_picker_type=calendar&checkin=2023-09-15&checkout=2023-09-16&source=structured_search_input_header&search_type=autocomplete_click",
                "_blank"
              )
            }
          />
        </Col>
        <Col xs={12} sm={6} md={{ span: 3, offset: 1 }}>
          <img
            className="accomodation-icon"
            src={"https://pageflows.com/media/logos/booking.jpg"}
            alt="booking"
            onClick={() =>
              window.open(
                "https://www.booking.com/searchresults.en-gb.html?label=gen173nr-1FCAEoggI46AdIM1gEaFCIAQGYAQm4ARfIAQzYAQHoAQH4AQuIAgGoAgO4AoC2kZ4GwAIB0gIkYTdlZmE2ZGItMDNkNy00YzBiLWJmYWMtNzAzZjNkN2NhNjZh2AIG4AIB&lang=en-gb&sid=e609d605af7bcc90767edad32499d899&sb=1&sb_lp=1&src=index&src_elem=sb&error_url=https%3A%2F%2Fwww.booking.com%2Findex.en-gb.html%3Flabel%3Dgen173nr-1FCAEoggI46AdIM1gEaFCIAQGYAQm4ARfIAQzYAQHoAQH4AQuIAgGoAgO4AoC2kZ4GwAIB0gIkYTdlZmE2ZGItMDNkNy00YzBiLWJmYWMtNzAzZjNkN2NhNjZh2AIG4AIB%26sid%3De609d605af7bcc90767edad32499d899%26sb_price_type%3Dtotal%26%26&ss=Wootton+Wawen%2C+Warwickshire%2C+United+Kingdom&is_ski_area=&ssne=Budapest&ssne_untouched=Budapest&checkin_year=2023&checkin_month=9&checkin_monthday=15&checkout_year=2023&checkout_month=9&checkout_monthday=16&flex_window=0&efdco=1&group_adults=2&group_children=0&no_rooms=1&b_h4u_keep_filters=&from_sf=1&search_pageview_id=3a968c801027053d&ac_suggestion_list_length=5&ac_suggestion_theme_list_length=0&ac_position=3&ac_langcode=en&ac_click_type=b&ac_meta=GhAzYTk2OGM4MDEwMjcwNTNkIAMoATICZW46Cldvb3R0b24gV2FAAEoAUAA%3D&dest_id=-2612107&dest_type=city&place_id_lat=52.25&place_id_lon=-1.76667&search_pageview_id=3a968c801027053d&search_selected=true&ss_raw=Wootton+Wa",
                "_blank"
              )
            }
          />
        </Col>
        <Col xs={12} sm={6} md={{ span: 3, offset: 1 }}>
          <img
            className="accomodation-icon"
            src={
              "https://cdn2.downdetector.com/static/uploads/logo/vBolYQ9O_400x400.jpg"
            }
            alt="premier-inn"
            onClick={() =>
              window.open(
                "https://www.premierinn.com/gb/en/search.html?searchModel.searchTerm=Wootton%20Wawen,%20Henley-in-Arden,%20UK&PLACEID=ChIJYcZ_4f_FcEgRcDn20TDHhWE&ARRdd=15&ARRmm=9&ARRyyyy=2023&NIGHTS=1&ROOMS=1&ADULT1=1&CHILD1=0&COT1=0&INTTYP1=DB&BOOKINGCHANNEL=WEB&SORT=1&VIEW=2",
                "_blank"
              )
            }
          />
        </Col>
      </Row>
    </Container>
  );
};
