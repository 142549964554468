// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import 'firebase/auth';
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: "matt-becca.appspot.com",
  messagingSenderId: "166061478463",
  appId: "1:166061478463:web:3c02c644ea5a9719235e8a",
  measurementId: "G-QFF7B37N2R"
};

// Initialize Firebase
const firebase = initializeApp(firebaseConfig);
const db = getFirestore();

export { firebase, db };