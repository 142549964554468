import { FC } from 'react';

import './PrimaryHeading.css';
import '../../fonts.css';

interface PrimaryHeadingProps {
    preSlash: string,
    postSlash: string
}

const PrimaryHeading: FC<PrimaryHeadingProps> = ({ preSlash, postSlash }) => {

    return(
        <h2><span>{ preSlash }</span> { postSlash } </h2>
    )
};

export default PrimaryHeading;