import { FC, useContext } from "react";

import { Row, Col, Card, Stack } from "react-bootstrap";

import {
  PlaylistItem,
  postUpvote,
  postDownvote,
} from "../../utils/playlist.utils";
import "./Playlist.css";

import { UserContext } from "../../utils/user.context";

// icons
import { CaretUpFill, CaretDownFill } from "react-bootstrap-icons";

const PlaylistVotingCard: FC<PlaylistItem> = (songData): JSX.Element => {
  // Context API's
  const { user } = useContext(UserContext);

  return (
    <Col>
      <Card className="playlist-item">
        <Row className="align-items-center">
          <Col xs={4} md={3} lg={2}>
            <Card.Img className="album-cover" src={songData.albumCover} />
          </Col>
          <Col>
            {songData.artist}
            <Card.Title>{songData.song}</Card.Title>
            <i>Submitted By: {songData.submittedBy}</i>
          </Col>
          <Col xs={2} lg={1}>
            <Row className="justify-contents-center">
              <Stack gap={1}>
                <CaretUpFill
                  className="voting-arrow"
                  size={35}
                  color={songData.voteFormatting.upvoteColor}
                  onClick={() =>
                    postUpvote(
                      songData.spotifyId,
                      user,
                      songData.score,
                      songData.upvotes,
                      songData.downvotes,
                      songData.voteFormatting.vote
                    )
                  }
                />
                <span className="vote-number">{songData.score}</span>
                <CaretDownFill
                  className="voting-arrow"
                  size={35}
                  color={songData.voteFormatting.downvoteColor}
                  onClick={() =>
                    postDownvote(
                      songData.spotifyId,
                      user,
                      songData.score,
                      songData.upvotes,
                      songData.downvotes,
                      songData.voteFormatting.vote
                    )
                  }
                />
              </Stack>
            </Row>
          </Col>
        </Row>
        <Row>
          <audio controls key={songData.spotifyId}>
            <source src={songData.track} type="audio/ogg" />
          </audio>
        </Row>
      </Card>
    </Col>
  );
};

export default PlaylistVotingCard;
