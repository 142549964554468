import { FC, useContext, useState, useEffect } from "react";

import { UserContext } from "../../utils/user.context";
import { updateLastPageVisit } from "../../utils/page.utils";

import "./OurStory.css";

import NavBar from "../../components/NavBar";
import Loading from "../../components/Loading";

const OurStory: FC = () => {
  // Context API's
  const { user } = useContext(UserContext);

  const [activeSection, setActiveSection] = useState<String>("2013");
  const [loading] = useState<boolean>(false);

  /*useEffect(() => {
    setTimeout(function () {
      setLoading(false);
    }, 2000);
  });*/

  document.body.style.overflow = "hidden";

  useEffect(() => {
    if (user) {
      updateLastPageVisit("Our Story", user.username);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="story-wrapper">
      <NavBar />
      {!loading ? (
        <>
          <nav className="nav__wrapper" id="story-navbar">
            <ul className="nav">
              <li
                role="presentation"
                className={activeSection === "2013" ? "active" : ""}
              >
                <a href="#section1" onClick={() => setActiveSection("2013")}>
                  <span className="nav__counter">2013</span>
                  <h3 className="nav__title">How We Met</h3>
                  <p className="nav__body nav__body__primary">
                    In September 2013 we both started on the same course at
                    Loughborough University. We shared all the same lectures
                    during our first year and it was during one of our
                    coursework lab sessions where we first met.
                  </p>
                  <p className="nav__body nav__body__alt">
                    We met during one of our coursework lab sessions at
                    Loughborough University, never expecting anything to
                    blossom.
                  </p>
                </a>
              </li>

              <li
                role="presentation"
                className={activeSection === "2015" ? "active" : ""}
              >
                <a href="#section2" onClick={() => setActiveSection("2015")}>
                  <span className="nav__counter">2015</span>
                  <h3 className="nav__title">Second Year of University</h3>
                  <p className="nav__body nav__body__primary">
                    Over the next 2 years we became really good friends, but it
                    wasn’t until the end of second year when friendship evolved
                    into a relationship.
                  </p>
                  <p className="nav__body nav__body__alt">
                    Friendship evolved into relationship at the end of second
                    year.
                  </p>
                </a>
              </li>

              <li
                role="presentation"
                className={activeSection === "2017" ? "active" : ""}
              >
                <a href="#section3" onClick={() => setActiveSection("2017")}>
                  <span className="nav__counter">2017</span>
                  <h3 className="nav__title">Graduation</h3>
                  <p className="nav__body nav__body__primary">
                    After handing in our dissertations (Matt singlehandidly
                    destroyed several acres of the Amazon), we were lucky enough
                    to enjoy our graduation ceremony together. Soon after that
                    we would step into the world of work and decided to move
                    into our first home together in Reading.
                  </p>
                  <p className="nav__body nav__body__alt">
                    We graduated and moved in together in Reading.
                  </p>
                </a>
              </li>

              <li
                role="presentation"
                className={activeSection === "2019" ? "active" : ""}
              >
                <a href="#section4" onClick={() => setActiveSection("2019")}>
                  <span className="nav__counter">2019</span>
                  <h3 className="nav__title">Moving to London</h3>
                  <p className="nav__body nav__body__primary">
                    Over the following years, we made many memories, enjoyed
                    many trips with friend and collected lots of furniture. With
                    Becca securing a new job at Sky in 2019 and Matt beginning
                    his second long-term relationship with Google in the AI
                    Labs, we decided to move to London.
                  </p>
                  <p className="nav__body nav__body__alt">
                    With Becca securing a new job at Sky and Matt beginning his
                    second long-term relationship with Google, we decided to
                    move to London.
                  </p>
                </a>
              </li>

              <li
                role="presentation"
                className={activeSection === "2020" ? "active" : ""}
              >
                <a href="#section5" onClick={() => setActiveSection("2020")}>
                  <span className="nav__counter">2020</span>
                  <h3 className="nav__title">Buying Our First Home</h3>
                  <p className="nav__body nav__body__primary">
                    We have achieved so much together, and 2020 was no
                    exception. We survived lockdown at Becca's parents, got
                    promoted together and purchased our first house in
                    Wokingham.
                  </p>
                  <p className="nav__body nav__body__alt">
                    During the pandemic, we decided London wasn't for us and
                    purchased our first home in Wokingham.
                  </p>
                </a>
              </li>

              <li
                role="presentation"
                className={activeSection === "2021" ? "active" : ""}
              >
                <a href="#section6" onClick={() => setActiveSection("2021")}>
                  <span className="nav__counter">2021</span>
                  <h3 className="nav__title">Getting Engaged</h3>
                  <p className="nav__body nav__body__primary nav__body__engaged">
                    Matt surprised Becca with her first trip to Silverstone on
                    her 26th Birthday and suggested to take a ride on the ferris
                    wheel to get out of the 31 degree heat. A quarter of the way
                    up, Matt panicked and posed the question. Despite the 200mph
                    action out the window, Becca maintained enough compusure to
                    say YES!
                  </p>
                  <p className="nav__body nav__body__alt">
                    Given Becca's obsession with F1, it was only natural for
                    Matt to propose during her first ever race at Silverstone.
                  </p>
                </a>
              </li>
              <li
                role="presentation"
                className={activeSection === "2023" ? "active" : ""}
              >
                <a href="#section7" onClick={() => setActiveSection("2023")}>
                  <span className="nav__counter">2023</span>
                  <h3 className="nav__title">Getting Married</h3>
                  <p className="nav__body">
                    So as we embark on this next chapter of our lives together,
                    we invite you to join us to celebrate on our special day!
                  </p>
                </a>
              </li>
            </ul>
          </nav>

          <section
            className="story-section story-section1"
            id="section1"
          ></section>

          <section
            className="story-section story-section2"
            id="section2"
          ></section>

          <section
            className="story-section story-section3"
            id="section3"
          ></section>

          <section
            className="story-section story-section4"
            id="section4"
          ></section>

          <section
            className="story-section story-section5"
            id="section5"
          ></section>

          <section
            className="story-section story-section6"
            id="section6"
          ></section>

          <section
            className="story-section story-section7"
            id="section7"
          ></section>
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default OurStory;
