import { db } from "../../utils/firebase-config.js";
import { addDoc, collection } from "firebase/firestore";

// uploading metadata of image to firestore
export async function uploadImageMetadata(
  username: string,
  category: string
): Promise<string> {
  // Add a new document in collection "cities"
  const imgRef = await addDoc(collection(db, "images"), {
    uploader: username,
    category,
    uploadDate: new Date(),
  });

  return imgRef.id;
}
