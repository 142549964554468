import { FC, useContext, useState, MouseEvent } from "react";

import { Navigate } from "react-router-dom";

import { firebase, db } from "../../utils/firebase-config.js";
import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
import { query, collection, where, onSnapshot } from "firebase/firestore";

import { UserContext } from "../../utils/user.context";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import toast from "react-hot-toast";

import "./Login.css";

import LoginIcon from "./img/login-icon.png";

// Create auth object
const authentication = getAuth(firebase);

const Login: FC = () => {
  // Context API's
  const userContext = useContext(UserContext);

  // OTP display state
  const [otpDisplay, setOtpDisplay] = useState(false);
  // phone number and otp state
  const [phoneNumber, setPhoneNumber] = useState("");

  // redirect user to landing page if already logged in
  if (userContext.user) {
    return <Navigate to="/" replace />;
  }

  // Check the user exists and generate reCaptcha
  const requestOTP = (event: MouseEvent) => {
    event.preventDefault();

    const extensionCodePhoneNumber = "+" + phoneNumber;

    if (phoneNumber.length >= 12) {
      // Query to fetch documents where phone number field is equal to given phone number
      const q = query(
        collection(db, "users"),
        where("phoneNumber", "==", extensionCodePhoneNumber)
      );

      onSnapshot(q, (snap: any) => {
        if (!snap.empty) {
          generateRecaptcha();
          let appVerifier = window.recaptchaVerifier;
          signInWithPhoneNumber(
            authentication,
            extensionCodePhoneNumber,
            appVerifier
          )
            .then((res) => {
              window.confirmationResult = res;
              setOtpDisplay(true); // display OTP input field
            })
            .catch((error) => {
              console.log(error);
              // Error: SMS not sent.
              toast.error(
                "Our website is down at the moment. Please try again later."
              );
            });
        } else {
          toast.error(
            "Phone number does not exist. Please enter a registered phone number."
          );
        }
      });
    } else {
      toast.error("Please enter a valid phone number");
    }
  };

  // reCAPTCHA
  const generateRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response: any) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          console.log(response);
        },
      },
      authentication
    );
  };

  // Verify OTP
  const verifyOtp = (otp: string) => {
    if (otp.length === 6) {
      let confirmationResult = window.confirmationResult;
      confirmationResult
        .confirm(otp)
        .then((result: any) => {
          // User signed in successfully.
          const user = result.user;
          //userContext.setUser("mattay boi");
          console.log(user);
        })
        .catch((error: any) => {
          // User couldn't sign in (bad verification code?)
          toast.error("Verification code is incorrect. Please try again.");
        });
    }
  };

  // phone field onChange listener
  const phoneNumberUpdate = (number: string) => {
    setPhoneNumber(number);
  };

  return (
    <>
      <div
        className="Auth-form-container"
        style={{
          backgroundImage:
            'url("https://storage.googleapis.com/matt-becca-test-image-assets/login-backgro.jpeg")',
          backgroundPosition: "center",
        }}
      >
        <form className="Auth-form">
          <div className="Auth-form-content">
            <h2>Welcome to</h2>
            <img className="login-icon" src={LoginIcon} alt="login-icon" />
            <h1> Matt & Becca's</h1>
            <h2>Wedding</h2>
            <div className="form-group mt-3">
              <h3>
                <label>Login with your Mobile Phone</label>
              </h3>
            </div>

            {!otpDisplay ? (
              <>
                <PhoneInput
                  country={"gb"}
                  onlyCountries={["gb", "us", "ae", "au"]}
                  value={phoneNumber}
                  onChange={(number) => phoneNumberUpdate(number)}
                  containerClass="phone-number-container"
                  inputClass="phone-number"
                  dropdownClass="extension-code-dropdown"
                />
                <div className="d-grid gap-2 mt-3">
                  <button className="submit-button" onClick={requestOTP}>
                    Request Text
                  </button>
                </div>
              </>
            ) : (
              <>
                <input
                  type="number"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    verifyOtp(event.currentTarget.value)
                  }
                />
              </>
            )}
          </div>
        </form>
        <div id="recaptcha-container"></div>
      </div>
    </>
  );
};

export default Login;
