export interface Countdown {
  days: string;
  hours: string;
  minutes: string;
  seconds: string;
  dayWording: string;
  hourWording: string;
  minuteWording: string;
  secondWording: string;
  weddingStarted: boolean;
}

export function countdownToWedding(weddingDate: Date, now: Date): Countdown {
  let days: number = 0;
  let hours: number = 0;
  let minutes: number = 0;
  let seconds: number = 0;
  let weddingStarted: boolean = false;

  // Calculate milliseconds
  const msBetweenDates = weddingDate.getTime() - now.getTime();

  // convert milliseconds to date time format if date is prior to wedding
  if (msBetweenDates > 0) {
    let secondsBetweenDates = msBetweenDates / 1000;
    days = Math.floor(secondsBetweenDates / (3600 * 24));
    hours = Math.floor((secondsBetweenDates % (3600 * 24)) / 3600);
    minutes = Math.floor((secondsBetweenDates % 3600) / 60);
    seconds = Math.ceil(secondsBetweenDates % 60);
  } else {
    days = 0;
    hours = 0;
    minutes = 0;
    seconds = 0;
    weddingStarted = true;
  }

  return {
    days: formatDateValue(days),
    hours: formatDateValue(hours),
    minutes: formatDateValue(minutes),
    seconds: formatDateValue(seconds),
    dayWording: formatDateWording(days, "day"),
    hourWording: formatDateWording(hours, "hour"),
    minuteWording: formatDateWording(minutes, "minute"),
    secondWording: formatDateWording(seconds, "second"),
    weddingStarted,
  };
}

// add a "0" upfront if value is less than 10
function formatDateValue(input: number): string {
  return input < 10 ? "0" + input : String(input);
}

// append wording with an "s" if there are multiple remaining e.g. changing day to days
function formatDateWording(input: number, wording: string): string {
  return input !== 1 ? wording + "s" : wording;
}

// update timer state every second
export function updateTimerState(input: Countdown): Countdown {
  if (input.seconds === "00") {
    input.seconds = "59"; // reset seconds

    if (input.minutes === "00") {
      input.minutes = "59"; // reset minutes

      if (input.hours === "00") {
        input.hours = "59"; // reset hours

        if (input.days === "00") {
          input.days = "00";
          input.hours = "00";
          input.minutes = "00";
          input.seconds = "00";
          input.weddingStarted = true;
        } else {
          input.days = formatDateValue(Number(input.days) - 1); // reduce hours
        }
      } else {
        input.hours = formatDateValue(Number(input.hours) - 1); // reduce hours
      }
    } else {
      input.minutes = formatDateValue(Number(input.minutes) - 1); // reduce minutes
    }
  } else {
    input.seconds = formatDateValue(Number(input.seconds) - 1); // reduce seconds
  }

  return {
    days: input.days,
    hours: input.hours,
    minutes: input.minutes,
    seconds: input.seconds,
    dayWording: formatDateWording(Number(input.days), "day"),
    hourWording: formatDateWording(Number(input.hours), "hour"),
    minuteWording: formatDateWording(Number(input.minutes), "minute"),
    secondWording: formatDateWording(Number(input.seconds), "second"),
    weddingStarted: input.weddingStarted,
  };
}
