import React from "react";

import { Routes, Route } from "react-router-dom";
import PrivateRoutes from "./PrivateRoutes";

import Login from "../pages/Login";
import LandingPage from "../pages/LandingPage";
import Travel from "../pages/Travel";
import OurStory from "../pages/OurStory";
import UploadGallery from "../pages/Gallery";
import Playlist from "../pages/Playlist";
import Questions from "../pages/Questions";

import Loading from "../components/Loading";

import { UserContext } from "./user.context";

function PageRouter() {
  const userContext = React.useContext(UserContext);

  return (
    <>
      {!userContext.loading ? (
        <Routes>
          <Route element={<PrivateRoutes />}>
            <Route path="/" element={<LandingPage />} />
            <Route path="travel" element={<Travel />} />
            <Route path="story" element={<OurStory />} />
            <Route path="gallery" element={<UploadGallery />} />
            <Route path="playlist" element={<Playlist />} />
            <Route path="questions" element={<Questions />} />
          </Route>
          <Route path="/login" element={<Login />} />
        </Routes>
      ) : (
        <Loading />
      )}
    </>
  );
}

export default PageRouter;
