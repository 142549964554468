import React, { createContext, useState, useEffect } from "react";
import { firebase } from "./firebase-config";
import { getAuth } from "firebase/auth";
import { collection, query, where, getDocs } from "firebase/firestore";

import { db } from "./firebase-config.js";

// types
import { User } from "../types/global.types";

interface AuthObject {
  user: User | null;
  setUser(user: User): void;
  loading: boolean;
  logout(): void;
  buildUserContext(phoneNumber: string): void;
}

export const UserContext = createContext<AuthObject>({
  user: null,
  setUser() {
    return;
  },
  loading: true,
  logout() {
    return;
  },
  buildUserContext() {
    return;
  },
});

// Create auth object
const authentication = getAuth(firebase);

export const UserProvider: React.FC = (props: any) => {
  // Initiate State
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    authentication.onAuthStateChanged((authState: any) => {
      if (authState) {
        buildUserContext(authState.phoneNumber);
      } else {
        setUser(null);
        setLoading(false);
      }
    });
  }, []);

  const buildUserContext = async (phoneNumber: string) => {
    const usersRef = collection(db, "users");

    // Create a query against the collection to find user with respective phoneNumber.
    const q = query(usersRef, where("phoneNumber", "==", phoneNumber));

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      let userData: User = {
        username: doc.id,
        firstName: doc.data().firstName,
        lastName: doc.data().lastName,
        phoneNumber: phoneNumber,
        dayGuest: doc.data().dayGuest,
        songSubmissionsRemaining: doc.data().songSubmissionsRemaining,
      };

      // sets dietaryRequirements if these have already been specified
      if (doc.data().rsvpResponse) {
        userData.rsvpResponse = doc.data().rsvpResponse;
      }

      if (doc.data().weddingBreakfastResponse) {
        userData.weddingBreakfastResponse = doc.data().weddingBreakfastResponse;
      }

      if (doc.data().partner) {
        userData.partner = {
          username: doc.data().partner.username,
          name: doc.data().partner.name,
        };
      }

      setUser(userData);
      setLoading(false);
    });
  };

  const logout = () => {
    authentication.signOut().then(() => {
      setUser(null);
    });
  };

  return (
    <UserContext.Provider
      value={{ user, setUser, loading, logout, buildUserContext }}
    >
      {props.children}
    </UserContext.Provider>
  );
};
