import React from "react";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";

interface LocationData {
  type: string;
  url: string;
  geocode: LocationGeocode;
}

interface LocationGeocode {
  lat: number;
  lng: number;
}

const locations: Array<LocationData> = [
  {
    type: "wedding-location",
    url: "https://www.woottonpark.co.uk/",
    geocode: { lat: 52.262627, lng: -1.794273 },
  },
  {
    type: "hotel",
    url: "https://www.premierinn.com/gb/en/hotels/england/warwickshire/stratford-upon-avon/stratford-upon-avon-waterways.html",
    geocode: { lat: 52.197278, lng: -1.711913 },
  },
  {
    type: "hotel",
    url: "https://www.ihg.com/crowneplaza/hotels/gb/en/stratford-upon-avon/sfdua/hoteldetail?cm_mmc=YextLocal-_-CP-_-GB-_-SFDUA",
    geocode: { lat: 52.192992, lng: -1.700456 },
  },
  {
    type: "hotel",
    url: "https://thewhiteswanhotel.com/",
    geocode: { lat: 52.292549, lng: -1.779866 },
  },
  {
    type: "hotel",
    url: "https://www.booking.com/hotel/gb/the-old-rectory-redditch.en-gb.html?aid=355028&sid=e609d605af7bcc90767edad32499d899&atlas_src=sr_iw_btn;dest_id=0;dist=0;group_adults=2;group_children=0;nflt=ht_id%3D204;no_rooms=1;room1=A%2CA;sb_price_type=total;type=total;ucfs=1&",
    geocode: { lat: 52.298183, lng: -1.901007 },
  },
  {
    type: "hotel",
    url: "https://www.kingscourthotel.co.uk/",
    geocode: { lat: 52.231665, lng: -1.880499 },
  },
  {
    type: "hotel",
    url: "https://www.booking.com/hotel/gb/warwickexpressbyholidayinn.en-gb.html?aid=355028&sid=e609d605af7bcc90767edad32499d899&dest_id=-2592687;dest_type=city;dist=0;group_adults=2;group_children=0;hapos=4;hpos=4;nflt=ht_id%3D204;no_rooms=1;req_adults=2;req_children=0;room1=A%2CA;sb_price_type=total;sr_order=popularity;srepoch=1665942372;srpvid=1a9f7cecb99201a3;type=total;ucfs=1&#hotelTmpl",
    geocode: { lat: 52.259771, lng: -1.609695 },
  },
];

const containerStyle = {
  width: "100%",
  height: "700px",
};

export function TravelMap() {
  const { isLoaded } = useJsApiLoader({
    id: "travel-map",
    googleMapsApiKey: process.env.REACT_APP_GMP_KEY,
  });

  const [map, setMap] = React.useState(null);
  if (map) {
    // required as map is not called elsewhere
  }

  const onLoad = React.useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  return isLoaded ? (
    <GoogleMap
      options={{
        mapId: "3ed7b94b90cc44e2",
      }}
      mapContainerStyle={containerStyle}
      center={locations[0].geocode}
      zoom={11}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {/* Child components, such as markers, info windows, etc. */}
      {locations.map((location) => {
        return (
          <Marker
            position={location.geocode}
            onClick={() => window.open(location.url, "_blank")}
            icon={`https://storage.googleapis.com/wedding-website-assets/icons/${location.type}.png`}
          />
        );
      })}
    </GoogleMap>
  ) : (
    <></>
  );
}
