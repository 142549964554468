import { FC, useContext, useState, useEffect } from "react";

import { UserContext } from "../../utils/user.context";
import { db } from "../../utils/firebase-config.js";
import {
  query,
  collection,
  orderBy,
  limit,
  onSnapshot,
} from "firebase/firestore";

import { Container, Row } from "react-bootstrap";

import NavBar from "../../components/NavBar";
import Header from "../../components/Header";
import Loading from "../../components/Loading";
import SongSearch from "../../components/SongSearch";
import PlaylistVotingCard from "./PlaylistVotingCard";
import Footer from "../../components/Footer";

import { updateLastPageVisit } from "../../utils/page.utils";
import { PlaylistItem, setVote } from "../../utils/playlist.utils";

const Playlist: FC = () => {
  // Context API's
  const { user } = useContext(UserContext);

  const [playlistItems, setPlaylistItems] = useState<Array<PlaylistItem>>([]);

  const getSongs = () => {
    if (user != null) {
      const q = query(
        collection(db, "music"),
        orderBy("score", "desc"),
        limit(100)
      );

      onSnapshot(q, (querySnapshot) => {
        let newPlaylistItems: Array<PlaylistItem> = [];
        querySnapshot.forEach((doc) => {
          var docData = doc.data();
          newPlaylistItems.push({
            spotifyId: doc.id,
            artist: docData.artist,
            albumCover: docData.albumCover,
            song: docData.song,
            track: docData.track,
            submittedBy: docData.submittedBy,
            score: docData.score,
            upvotes: docData.upvotes,
            downvotes: docData.downvotes,
            voteFormatting: setVote(
              user.username,
              docData.upvotes,
              docData.downvotes
            ),
          });
        });
        setPlaylistItems(newPlaylistItems);
      });
    }
  };

  useEffect(() => {
    getSongs();
    if (user) {
      updateLastPageVisit("Playlist", user.username);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <NavBar /> {/* NavBar */}
      <Header headerText="Wedding Playlist" />
      <br />
      <br />
      <Container>
        <SongSearch />
        {playlistItems.length > 0 ? (
          playlistItems.map((playlistItem, index) => (
            <Row xs={1} key={index}>
              <PlaylistVotingCard {...playlistItem} />
            </Row>
          ))
        ) : (
          <Loading />
        )}
      </Container>
      <Footer />
    </>
  );
};

export default Playlist;
