import { FC, useState } from "react";

import { ListGroup, Offcanvas } from "react-bootstrap";

// icons
import { List } from "react-bootstrap-icons";
import MenuIcon from "./assets/hamburger-menu-logo.png";

import "./navbar.css";

const NavBar: FC = () => {
  // Hamburger Menu vs Menu list
  const handleWindowResize = (): boolean => {
    if (window.innerWidth >= 1064) {
      return false;
    } else {
      return true;
    }
  };

  const [hamburgerMenu, setHamburgerMenu] =
    useState<boolean>(handleWindowResize);
  const [navBarActive, setNavBarActive] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);

  const handleScroll = () => {
    // Navbar background colour transparent vs white
    if (window.scrollY >= 50) {
      setNavBarActive(true);
    } else {
      setNavBarActive(false);
    }
  };

  // event listeners to handle use state changes
  window.addEventListener("resize", () =>
    setHamburgerMenu(handleWindowResize())
  );
  window.addEventListener("scroll", handleScroll);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      {window.location.href === process.env.REACT_APP_HOST_NAME &&
      !navBarActive /* NavBar hidden if on homepage and user is scrolled to the top */ ? (
        <></>
      ) : (
        <>
          <nav className={navBarActive ? "navbar navbar-active" : "navbar"}>
            {hamburgerMenu ? (
              <List
                className="hamburger-menu"
                onClick={handleShow}
                color={navBarActive ? "#6F3092" : "#fff"}
                size={50}
              />
            ) : (
              <ul className="menu-items">
                <li className="menu-item">
                  <a
                    href={`${process.env.REACT_APP_HOST_NAME}questions`}
                    className={
                      navBarActive ? "menu-item-link" : "menu-item-link-white"
                    }
                  >
                    q&a
                  </a>
                </li>
                <li className="menu-item">
                  <a
                    href={`${process.env.REACT_APP_HOST_NAME}travel`}
                    className={
                      navBarActive ? "menu-item-link" : "menu-item-link-white"
                    }
                  >
                    Travel
                  </a>
                </li>
                <li className="menu-item">
                  <a
                    href={`${process.env.REACT_APP_HOST_NAME}gallery`}
                    className={
                      navBarActive ? "menu-item-link" : "menu-item-link-white"
                    }
                  >
                    Gallery
                  </a>
                </li>
                <li className="menu-item">
                  <a
                    href={`${process.env.REACT_APP_HOST_NAME}`}
                    className={
                      navBarActive ? "menu-item-link" : "menu-item-link-white"
                    }
                  >
                    Home
                  </a>
                </li>
              </ul>
            )}
          </nav>

          <Offcanvas show={show} onHide={handleClose} placement="end">
            <Offcanvas.Header closeButton />
            <img
              src={MenuIcon}
              alt="hamburger-logo"
              className="hamburger-logo"
            />
            <br />
            <Offcanvas.Body>
              <ListGroup>
                <ListGroup.Item
                  action
                  href={`https://matt-becca.uk/`}
                  className="hamburger-menu-item"
                >
                  Home
                </ListGroup.Item>
                <ListGroup.Item
                  action
                  href={`https://matt-becca.uk/story`}
                  className="hamburger-menu-item"
                >
                  Our Story
                </ListGroup.Item>
                <ListGroup.Item
                  action
                  href={`https://matt-becca.uk/gallery`}
                  className="hamburger-menu-item"
                >
                  Gallery
                </ListGroup.Item>
                <ListGroup.Item
                  action
                  href={`https://giftlist.kuoni.co.uk/gift-contribute?ref=43489`}
                  className="hamburger-menu-item"
                >
                  Giftlist
                </ListGroup.Item>
                <ListGroup.Item
                  action
                  href={`https://matt-becca.uk/travel`}
                  className="hamburger-menu-item"
                >
                  Travel & Accomodation
                </ListGroup.Item>
                <ListGroup.Item
                  action
                  href={`https://matt-becca.uk/playlist`}
                  className="hamburger-menu-item"
                >
                  Choose the Playlist
                </ListGroup.Item>
                <ListGroup.Item
                  action
                  href={`https://matt-becca.uk/questions`}
                  className="hamburger-menu-item"
                >
                  Q&A
                </ListGroup.Item>
              </ListGroup>
            </Offcanvas.Body>
          </Offcanvas>
        </>
      )}
    </>
  );
};

export default NavBar;
