import React from "react";

import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";

import "./landingpagemap.css";

const containerStyle = {
  width: "100%",
  height: "400px",
};

const woottonLocation = {
  lat: 52.262627,
  lng: -1.794273,
};

const center = {
  lat: 52.562627,
  lng: -1.794273,
};

function LandingPageMap() {
  const { isLoaded } = useJsApiLoader({
    id: "wootton-park",
    googleMapsApiKey: process.env.REACT_APP_GMP_KEY,
  });

  const [map, setMap] = React.useState(null);
  if (map) {
    // required as map is not called elsewhere
  }

  const onLoad = React.useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  return isLoaded ? (
    <a href="https://www.woottonparkweddings.co.uk/">
      <GoogleMap
        options={{
          mapId: "3ed7b94b90cc44e2",
          gestureHandling: "none", // removes zoom and scroll functionality
          disableDefaultUI: true, // removes default Google Maps controls
        }}
        mapContainerStyle={containerStyle}
        center={center}
        zoom={8}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        {/* Child components, such as markers, info windows, etc. */}
        <Marker
          animation={1}
          position={woottonLocation}
          icon="https://storage.googleapis.com/wedding-website-assets/icons/wedding-location.png"
        >
          <InfoWindow>
            <div id="landing-page-infowindow">
              <img
                src="https://www.woottonparkweddings.co.uk/wp-content/uploads/sites/54/2021/12/xWootton-Park-Logo-Colour-Dec-2021-T1-PNG.png.pagespeed.ic.pSfSEbDh6q.png"
                alt="wootton-logo"
                className="wootton-logo"
              />
              <br />
              Wootton Wawen <br /> Henley-in-Arden <br />
              B95 6HJ
            </div>
          </InfoWindow>
        </Marker>
      </GoogleMap>
    </a>
  ) : (
    <></>
  );
}

export default React.memo(LandingPageMap);
