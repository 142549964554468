import { useState, useContext, useEffect } from "react";

import { db } from "../../../utils/firebase-config.js";
import { doc, writeBatch } from "firebase/firestore";

import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import ListGroup from "react-bootstrap/ListGroup";

import PrimaryButton from "../../../components/PrimaryButton";
import toast from "react-hot-toast";

import { RsvpResponse } from "../../../types/global.types";
import { UserContext } from "../../../utils/user.context";

export function RSVP(props: any) {
  const { user, buildUserContext } = useContext(UserContext);

  const [attending, setAttending] = useState<boolean>(true);
  const [dietaryRequirements, setDietaryRequirements] = useState<string>("");
  const [partnerResponse, setPartnerResponse] = useState<boolean>(false);

  // populates input fields with existing RSVP responses
  useEffect(() => {
    if (user != null) {
      if (user.rsvpResponse) {
        setAttending(user.rsvpResponse.attending);
        setDietaryRequirements(user.rsvpResponse.dietaryRequirements);
      }
    }
  }, [user]);

  // uploads user response to firestore.
  const submitResponses = async () => {
    if (user != null) {
      // construct data object
      let rsvpResponse: RsvpResponse = {
        attending,
        dietaryRequirements,
        submissionDate: new Date(),
        responder: user.username,
      };

      // Get a new write batch
      const batch = writeBatch(db);

      const userRef = doc(db, "users", user.username);
      batch.update(userRef, { rsvpResponse });

      if (user.partner && partnerResponse) {
        const partnerRef = doc(db, "users", user.partner.username);
        batch.update(partnerRef, { rsvpResponse });
      }

      batch
        .commit()
        .then(() => {
          toast.success(`Thank you for submitting your RSVP response`);
        })
        .catch(() => {
          toast.error(
            `Unfortunately, your RSVP response did not submit. Please try again.`
          );
        });

      setPartnerResponse(false);
      buildUserContext(user.phoneNumber);
      props.onHide();
    }
  };

  return (
    <>
      <Modal {...props}>
        <Modal.Body className="modal-container">
          <Modal.Header closeButton className="modal-title">
            <Modal.Title>RSVP</Modal.Title>
          </Modal.Header>
          {user?.dayGuest ? (
            <p className="modal-text">
              You are invited to join Matthew Cresswell & Rebecca Adams on our
              <h1>Wedding Day</h1>
            </p>
          ) : (
            <p className="modal-text">
              You are invited to the
              <h1>evening reception </h1>
              of Matthew Cresswell & Rebecca Adams.
            </p>
          )}
          <br />
          <Form>
            <ListGroup horizontal>
              <ListGroup.Item
                onClick={() => setAttending(true)}
                className={
                  attending
                    ? "rsvp-response response-selected"
                    : "rsvp-response"
                }
              >
                Wouldn't miss it for the world
              </ListGroup.Item>
              <ListGroup.Item
                onClick={() => setAttending(false)}
                className={
                  attending
                    ? "rsvp-response"
                    : "rsvp-response response-selected"
                }
              >
                I will be there in spirit
              </ListGroup.Item>
            </ListGroup>
            <br />
            {user?.partner?.username ? (
              <Form.Check
                type="switch"
                id="partner-switch"
                onClick={() => setPartnerResponse(!partnerResponse)}
                label={`Response also on behalf of ${user.partner.name}`}
              />
            ) : (
              <></>
            )}
            <br />
            <Form.Group
              className={`mb-3 ${
                attending
                  ? "dietary-requirements-fade-in"
                  : "dietary-requirements-fade-out"
              }`}
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Do you have any dietary requirements?</Form.Label>
              <Form.Control
                as="textarea"
                id="dietary-requirements"
                value={dietaryRequirements}
                onChange={(e) => setDietaryRequirements(e.target.value)}
                rows={3}
              />
            </Form.Group>
          </Form>
          <Modal.Footer style={{ border: "none" }}>
            <PrimaryButton handleClick={submitResponses} text="Submit" />
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </>
  );
}
