import { db } from "./firebase-config.js";
import { doc, updateDoc } from "firebase/firestore";

export const updateLastPageVisit = async (page: string, username: any) => {
  const lastVisitRef = doc(db, "users", username);

  await updateDoc(lastVisitRef, {
    lastVisit: {
      page,
      dateTime: new Date(),
    },
  });
};
