// @ts-nocheck
import { useState, useEffect, useContext } from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { Gallery } from "react-grid-gallery";
import Lightbox from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/dist/plugins/captions";
import Thumbnails from "yet-another-react-lightbox/dist/plugins/thumbnails";
import Download from "yet-another-react-lightbox/dist/plugins/download";
import Zoom from "yet-another-react-lightbox/dist/plugins/zoom";
import "yet-another-react-lightbox/dist/styles.css";
import "yet-another-react-lightbox/dist/plugins/captions/captions.css";
import "yet-another-react-lightbox/dist/plugins/thumbnails/thumbnails.css";
import toast from "react-hot-toast";

import NavBar from "../../components/NavBar";
import Header from "../../components/Header";
import PrimaryButton from "../../components/PrimaryButton";
import Loading from "../../components/Loading";
import Footer from "../../components/Footer";

import { query, getDocs, collection, orderBy } from "firebase/firestore";
import { db } from "../../utils/firebase-config.js";
import { firebase } from "../../utils/firebase-config";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { uploadImageMetadata } from "./imageMetadata.utils";

import { UserContext } from "../../utils/user.context";

import "./Gallery.css";

const UploadGallery: FC = () => {
  const { user } = useContext(UserContext);

  const [fullImageList, setFullImageList] = useState([]);
  const [imageList, setImageList] = useState([]);
  const [loading, setLoading] = useState(true);

  const [slideIndex, setSlideIndex] = useState(-1);

  const [uploadModal, setUploadModal] = useState(false);
  const [categoryInput, setCategoryInput] = useState("Selfie With a Stranger");
  const [imageUpload, setImageUpload] = useState();
  const [imagePreview, setImagePreview] = useState();
  const [categoryFilter, setCategoryFilter] = useState("");
  const [uploaderFilter, setUploaderFilter] = useState("");

  // handlers for opening and closing upload modal
  const displayUploadModal = () => setUploadModal(true);
  const closeUploadModal = () => setUploadModal(false);

  const handleImageClick = (slideIndex) => setSlideIndex(slideIndex);

  const storage = getStorage(firebase);

  const filterImages = (uploaderSearch, categorySearch) => {
    setLoading(true);
    var i = 0;
    let newImageList = [];
    if (uploaderSearch) {
      if (categorySearch) {
        for (i = 0; i < fullImageList.length; i++) {
          if (
            fullImageList[i].tags[0].value
              .toLowerCase()
              .indexOf(uploaderSearch.toLowerCase()) > -1 &&
            fullImageList[i].tags[1].value === categorySearch
          ) {
            newImageList.push(fullImageList[i]);
          }
        }
      } else {
        for (i = 0; i < fullImageList.length; i++) {
          if (
            fullImageList[i].tags[0].value
              .toLowerCase()
              .indexOf(uploaderSearch.toLowerCase()) > -1
          ) {
            newImageList.push(fullImageList[i]);
          }
        }
      }
    } else if (categorySearch) {
      for (i = 0; i < fullImageList.length; i++) {
        if (fullImageList[i].tags[1].value === categorySearch) {
          newImageList.push(fullImageList[i]);
        }
      }
    } else {
      newImageList = fullImageList;
    }
    setImageList(newImageList);
    setLoading(false);
  };

  // handler to validate file upload type and save to state
  const handleImageUpload = (file) => {
    if (file.type.indexOf("image") === -1) {
      toast.error(
        `Please upload a picture. Post any videos to our shared album!`
      );
    } else {
      setImageUpload(file);
      setImagePreview(URL.createObjectURL(file));
    }
  };

  // upload file to GCS
  const uploadFile = () => {
    if (user !== null) {
      if (imageUpload) {
        uploadImageMetadata(`${user.firstName} ${user.lastName}`, categoryInput)
          .then((imageId) => {
            const imageRef = ref(storage, `images/${imageId}`);
            uploadBytes(imageRef, imageUpload).then((snapshot) => {
              getDownloadURL(snapshot.ref).then((url) => {
                setFullImageList((prev) => [
                  ...prev,
                  {
                    src: url,
                    width: 320,
                    height: 380,
                    tags: [
                      {
                        value: `${user.firstName} ${user.lastName}`,
                        title: `${user.firstName} ${user.lastName}`,
                      },
                      { value: categoryInput, title: categoryInput },
                    ],
                  },
                ]);
              });
            });
          })
          .then(() => {
            closeUploadModal();
            setImageUpload();
            setImagePreview();
          })
          .catch(() => {
            toast.error(
              `Unfortunately we could not upload your image. Please check your network and try again.`
            );
          });
      } else {
        toast.error(`Please select an image to upload.`);
      }
    }
  };

  useEffect(() => {
    filterImages(uploaderFilter, categoryFilter);
    // eslint-disable-next-line
  }, [fullImageList]);

  useEffect(() => {
    async function fetchAllImages() {
      const q = query(collection(db, "images"), orderBy("uploadDate"));

      const querySnapshot = await getDocs(q);

      let newFullImageList: Array<any> = [];
      querySnapshot.forEach((doc) => {
        var docData = doc.data();
        newFullImageList.push({
          src: `https://firebasestorage.googleapis.com/v0/b/matt-becca.appspot.com/o/images%2F${doc.id}?alt=media`,
          width: 320,
          height: 380,
          tags: [
            { value: docData.uploader, title: docData.uploader },
            { value: docData.category, title: docData.category },
          ],
        });
      });
      setFullImageList(newFullImageList);
      setLoading(false);
    }
    fetchAllImages();
  }, []);

  return (
    <>
      {/*          Modal           */}
      <Modal show={uploadModal} onHide={closeUploadModal}>
        <Modal.Body className="modal-container">
          <Modal.Header closeButton className="modal-title">
            <Modal.Title>Upload Images</Modal.Title>
          </Modal.Header>
          <Form.Select
            aria-label="Default select example"
            className="category-input"
            onChange={(e) => setCategoryInput(e.target.value)}
          >
            <option value="Selfie With a Stranger">
              Selfie With a Stranger
            </option>
            <option value="Wearing Same Colour">Wearing the Same Colour</option>
            <option value="Group Picture">Group Picture</option>
            <option value="Craziest Dance Shape">Craziest Dance Shape</option>
            <option value="Selfie with an Animal">Selfie with an Animal</option>
          </Form.Select>
          <br />
          <label className="image-upload-button">
            <input
              type="file"
              accept="image/*"
              onChange={(event) => {
                handleImageUpload(event.target.files[0]);
              }}
            />
            Upload Picture
          </label>
          <br />
          {imagePreview ? (
            <img
              src={imagePreview}
              height={200}
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              alt="img-upload"
            />
          ) : (
            <></>
          )}
          <Modal.Footer style={{ border: "none" }}>
            <PrimaryButton handleClick={uploadFile} text="Upload" />
          </Modal.Footer>
        </Modal.Body>
      </Modal>

      {/*          Body           */}
      <NavBar />
      <Header headerText="Gallery" />
      <Container>
        <br />
        <Container>
          <p className="gallery-summary">
            Take part in our{" "}
            <b
              className="gallery-summary-emphasized"
              onClick={displayUploadModal}
            >
              competition
            </b>{" "}
            by snapping a picture under one of the categories listed below, or
            you can share all your wonderful pictures & videos with us via the{" "}
            <a
              className="gallery-summary-emphasized"
              href="https://photos.app.goo.gl/fiefotP1i6d6TSm67"
              target="_blank"
              rel="noreferrer"
            >
              Shared Album!
            </a>
          </p>
          <Row className="gallery-buttons-wrapper">
            <div className="gallery-button-wrapper">
              <PrimaryButton
                handleClick={displayUploadModal}
                text="Category Upload"
              />
            </div>
            <div className="gallery-button-wrapper">
              <PrimaryButton
                handleClick={() =>
                  window
                    .open(
                      "https://photos.app.goo.gl/fiefotP1i6d6TSm67",
                      "_blank"
                    )
                    .focus()
                }
                text="Shared Album"
              />
            </div>
          </Row>
        </Container>
        <Row>
          <div className="input-select-wrapper">
            <Form.Select
              aria-label="Default select example"
              className="filter-search"
              onChange={(e) => {
                setCategoryFilter(e.target.value);
                filterImages(uploaderFilter, e.target.value);
              }}
            >
              <option value="">All categories</option>
              <option value="With a Stranger">Selfie with a Stranger</option>
              <option value="Wearing Same Colour">
                Wearing the Same Colour
              </option>
              <option value="Group Picture">Group Picture</option>
              <option value="Craziest Dance Shape">Craziest Dance Shape</option>
              <option value="Picture with an Animal">
                Selfie with an Animal
              </option>
            </Form.Select>
          </div>
        </Row>
        <br />
        <Row>
          <div className="input-select-wrapper">
            <Form.Control
              type="text"
              placeholder="Search Uploaders"
              className="filter-search"
              onChange={(e) => {
                setUploaderFilter(e.target.value);
                filterImages(e.target.value, categoryFilter);
              }}
            />
          </div>
        </Row>
      </Container>
      <br />
      {imageList && !loading ? (
        <section id="gallery">
          <Gallery
            images={imageList}
            onClick={handleImageClick}
            enableImageSelection={false}
            tagStyle={{
              display: "inline",
              padding: "0.2em 0.6em 0.3em",
              fontSize: "75%",
              fontWeight: 600,
              lineHeight: 1,
              color: "white",
              background: "rgba(0, 0, 0, 0.65)",
              textAlign: "center",
              whiteSpace: "nowrap",
              verticalAlign: "baseline",
              borderRadius: "0.25em",
            }}
          />
          <Lightbox
            slides={imageList.map(({ src, tags }) => ({
              src,
              download: src,
              description: `Submitted by: ${tags[0].value} \n Category: ${tags[1].value}`,
            }))}
            open={slideIndex >= 0}
            index={slideIndex}
            close={() => setSlideIndex(-1)}
            plugins={[Captions, Thumbnails, Download, Zoom]}
            key={`slide-${slideIndex}`}
          />
        </section>
      ) : (
        <Loading />
      )}
      <Footer />
    </>
  );
};

export default UploadGallery;
